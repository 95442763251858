<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <vb-headers-tag :data="{ title: 'Gérer les actualités' }" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-top card-top-danger">
          <div class="card-header py-0">
            <vb-headers-card-header-tabbed :data="{ title: 'Liste des actualités' }" />
          </div>
          <div class="card-body">
             <vb-controls-button-2
              to="/ajouter_actualites"
              :data="{ title: 'Ajouter actualités', icon: 'fe fe-plus-circle', type: 'primary' }"
            />
          </div>
            <div class="card-body"><actualites-table /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VbHeadersTag from '@/components/widgets/Headers/Tag'
import VbControlsButton2 from '@/components/widgets/Controls/Button2'
import VbHeadersCardHeaderTabbed from '@/components/widgets/Headers/CardHeader4'
import actualitesTable from '@/components/widgets/TablesAntd/actualites'

export default {
  name: 'Vbactualitess',
  components: {
    VbHeadersTag,
    VbHeadersCardHeaderTabbed,
    VbControlsButton2,
    actualitesTable,
  },
}
</script>
